import { Image, Subtitle1, Subtitle2, makeStyles, tokens } from '@fluentui/react-components';
import * as React from 'react';
import welcomeImage from '../assets/WelcomeImage.png';
import { GetResponseOptions } from '../libs/hooks/useChat';
import { useAppSelector } from '../redux/app/hooks';
import { RootState } from '../redux/app/store';
import { PredefinedQ } from './PredefinedQ';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        '@media (max-width: 744px)': {
            justifyContent: 'center',
        },
        '@media (min-width: 744px)': {
            justifyContent: 'space-between',
        },
        alignItems: 'center',
        height: '100%',
        width: '100%',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
    qContent: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        '@media (max-width: 744px)': {
            flexDirection: 'column',
        },
    },
    image: {
        objectFit: 'cover',
        width: '200px',
        height: '200px',
    },
    hide: {
        '@media (max-width: 744px), (max-height: 850px)': {
            display: 'none',
        },
    },
    hideUltraLow: {
        '@media (max-height: 550px)': {
            display: 'none',
        },
    },
    qButton: {
        paddingLeft: '10px',
        paddingRight: '10px',
        paddingTop: '20px',
    },
    qBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        verticalAlign: 'center',
    },
});

interface WelcomeProps {
    onSubmit: (question: GetResponseOptions) => Promise<void>;
}

export const Welcome: React.FC<WelcomeProps> = ({ onSubmit }) => {
    const classes = useStyles();
    const { customSettings } = useAppSelector((state: RootState) => state.customSettings);
    const welcomeQButtons = [
        customSettings.welcomeQButton1,
        customSettings.welcomeQButton2,
        customSettings.welcomeQButton3,
        customSettings.welcomeQButton4,
        customSettings.welcomeQButton5,
        customSettings.welcomeQButton6,
    ].filter((button) => button !== '');
    return (
        <div className={classes.root}>
            <div className={[classes.content, classes.hide].join(' ')}>
                <Subtitle1 as="h1">{customSettings.welcomeTitle}</Subtitle1>

                <Subtitle2 as="h2" style={{ color: tokens.colorBrandForeground2 }}>
                    {customSettings.welcomeSubtitle}
                </Subtitle2>
            </div>
            <div className={[classes.content, classes.hideUltraLow].join(' ')}>
                <Image
                    src={customSettings.welcomeImageUrl || welcomeImage}
                    className={classes.image}
                    style={{ borderRadius: '50%' }}
                />
                <Subtitle2 as="h2" style={{ padding: '30px', color: tokens.colorBrandForeground2 }}>
                    {customSettings.welcomeSubtitle2}
                </Subtitle2>
            </div>
            <div className={[classes.content, classes.hide].join(' ')}>
                {welcomeQButtons.length > 0 && (
                    <div className={classes.qBox}>
                        <div className={classes.qContent}>
                            {welcomeQButtons.slice(0, 3).map((button, index) => (
                                <div className={classes.qButton} key={index}>
                                    <PredefinedQ onSubmit={onSubmit} question={button} />
                                </div>
                            ))}
                        </div>
                        {welcomeQButtons.length > 3 && (
                            <div className={classes.qContent}>
                                {welcomeQButtons.slice(3, 6).map((button, index) => (
                                    <div className={classes.qButton} key={index + 3}>
                                        <PredefinedQ onSubmit={onSubmit} question={button} />
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                )}{' '}
            </div>
        </div>
    );
};
