import { Button, InfoLabel, Input, makeStyles, shorthands } from '@fluentui/react-components';
import { Save16Regular } from '@fluentui/react-icons';
import React, { useState } from 'react';
import { useSettings } from '../../../libs/hooks/useSettings';
import { useAppDispatch, useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { fetchCustomSettings } from '../../../redux/app/thunks';
import { ParameterField } from './ParameterField';

const useStyles = makeStyles({
    base: {
        display: 'flex',
        flexDirection: 'column',
    },
    Input: {
        width: '100%',
        minHeight: '25px',
        marginBottom: '10px',
    },
    button: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    infoButton: {
        ...shorthands.padding(0),
        ...shorthands.margin(0),
        minWidth: 'auto',
        marginLeft: 'auto',
    },
});

const CustomizationDialog: React.FC = () => {
    const customSettings = useAppSelector((state: RootState) => state.customSettings);
    const dispatch = useAppDispatch();
    const styles = useStyles();
    const settings = useSettings();
    const [appTitle, setAppTitle] = useState(customSettings.customSettings.appTitle);
    const [welcomeTitle, setWelcomeTitle] = useState(customSettings.customSettings.welcomeTitle);
    const [welcomeSubtitle, setWelcomeSubtitle] = useState(customSettings.customSettings.welcomeSubtitle);
    const [welcomeSubtitle2, setWelcomeSubtitle2] = useState(customSettings.customSettings.welcomeSubtitle2);
    // const [v10, setV10] = useState(customSettings.customSettings.brandVariants.v10);
    // const [v20, setV20] = useState(customSettings.customSettings.brandVariants.v20);
    // const [v30, setV30] = useState(customSettings.customSettings.brandVariants.v30);
    // const [v40, setV40] = useState(customSettings.customSettings.brandVariants.v40);
    // const [v50, setV50] = useState(customSettings.customSettings.brandVariants.v50);
    // const [v60, setV60] = useState(customSettings.customSettings.brandVariants.v60);
    const [v70, setV70] = useState(customSettings.customSettings.brandVariants.v70);
    const [v80, setV80] = useState(customSettings.customSettings.brandVariants.v80);
    //const [v90, setV90] = useState(customSettings.customSettings.brandVariants.v90);
    // const [v100, setV100] = useState(customSettings.customSettings.brandVariants.v100);
    // const [v110, setV110] = useState(customSettings.customSettings.brandVariants.v110);
    // const [v120, setV120] = useState(customSettings.customSettings.brandVariants.v120);
    // const [v130, setV130] = useState(customSettings.customSettings.brandVariants.v130);
    // const [v140, setV140] = useState(customSettings.customSettings.brandVariants.v140);
    // const [v150, setV150] = useState(customSettings.customSettings.brandVariants.v150);
    // const [v160, setV160] = useState(customSettings.customSettings.brandVariants.v160);
    const [question1, setQuestion1] = useState(customSettings.customSettings.welcomeQButton1);
    const [question2, setQuestion2] = useState(customSettings.customSettings.welcomeQButton2);
    const [question3, setQuestion3] = useState(customSettings.customSettings.welcomeQButton3);
    const [question4, setQuestion4] = useState(customSettings.customSettings.welcomeQButton4);
    const [question5, setQuestion5] = useState(customSettings.customSettings.welcomeQButton5);
    const [question6, setQuestion6] = useState(customSettings.customSettings.welcomeQButton6);
    const [chatbotImage, setChatbotImage] = useState(customSettings.customSettings.welcomeImageUrl);

    const handleSave = () => {
        const newCustomSettings = {
            ...customSettings.customSettings,
            appTitle,
            welcomeTitle,
            welcomeSubtitle,
            welcomeSubtitle2:
                welcomeSubtitle2.length > 5 ? welcomeSubtitle2 : customSettings.customSettings.welcomeSubtitle2,
            welcomeQButton1: question1,
            welcomeQButton2: question2,
            welcomeQButton3: question3,
            welcomeQButton4: question4,
            welcomeQButton5: question5,
            welcomeQButton6: question6,
            welcomeImageUrl: chatbotImage,
            brandVariants: {
                ...customSettings.customSettings.brandVariants,
                // v10: v10,
                // v20: v20,
                // v30: v30,
                // v40: v40,
                v50: '#F0F0F0',
                v60: v80.length > 3 && v80.startsWith('#') ? v80 : customSettings.customSettings.brandVariants.v80,
                v70: v70.length > 3 && v70.startsWith('#') ? v70 : customSettings.customSettings.brandVariants.v70,
                v80: v80.length > 3 && v80.startsWith('#') ? v80 : customSettings.customSettings.brandVariants.v80,
                v90: v80.length > 3 && v80.startsWith('#') ? v80 : customSettings.customSettings.brandVariants.v80,
                v100: v80.length > 3 && v80.startsWith('#') ? v80 : customSettings.customSettings.brandVariants.v80,
                v110: v70.length > 3 && v70.startsWith('#') ? v70 : customSettings.customSettings.brandVariants.v70,
                // v120: v120,
                // v130: v130,
                v140: '#F0F0F0',
                // v150: v150,
                // v160: v160,
            },
        };
        void settings.setSettings(newCustomSettings).then(() => {
            dispatch(fetchCustomSettings() as any);
        });
        setV70(newCustomSettings.brandVariants.v70);
        setV80(newCustomSettings.brandVariants.v80);
        setWelcomeSubtitle2(newCustomSettings.welcomeSubtitle2);
    };
    return (
        <div className={styles.base}>
            <div className={styles.button}>
                <h4>
                    <u>Text Elements</u>
                    <InfoLabel size="small" info="Define text elements for welcome screen"></InfoLabel>
                </h4>
                <Button onClick={handleSave} icon={<Save16Regular />} />
            </div>
            <ParameterField required={true} info="Title shown in the banner" bannerTitle="Banner Title">
                <Input
                    className={styles.Input}
                    value={appTitle}
                    onChange={(e) => {
                        setAppTitle(e.target.value);
                    }}
                />
            </ParameterField>
            <ParameterField info="Headline shown on the new chat screen" bannerTitle="Welcome Headline">
                <Input
                    className={styles.Input}
                    value={welcomeTitle}
                    onChange={(e) => {
                        setWelcomeTitle(e.target.value);
                    }}
                />
            </ParameterField>
            <ParameterField info="Subtitle shown on the new chat screen" bannerTitle="Welcome Subtitle">
                <Input
                    className={styles.Input}
                    value={welcomeSubtitle}
                    onChange={(e) => {
                        setWelcomeSubtitle(e.target.value);
                    }}
                />
            </ParameterField>
            <ParameterField required={true} info="Greeting shown on the new chat screen" bannerTitle="Welcome Greeting">
                <Input
                    className={styles.Input}
                    value={welcomeSubtitle2}
                    onChange={(e) => {
                        const value = e.target.value;
                        setWelcomeSubtitle2(value);
                    }}
                />
            </ParameterField>
            <div className={styles.button}>
                <h4>
                    <u>Color Scheme</u>
                    <InfoLabel
                        size="small"
                        info="The application uses colors from the Fluent Theme Designer. Find help to generate the custom brand colors here: https://react.fluentui.dev/?path=/docs/theme-theme-designer--page"
                    ></InfoLabel>
                </h4>
                <Button onClick={handleSave} icon={<Save16Regular />} />
            </div>
            {/* <ParameterField info="HEX color code for v10 (#)" bannerTitle="v10 Color">
                <Input
                    className={styles.Input}
                    value={v10}
                    onChange={(e) => {
                        setV10(e.target.value);
                    }}
                />
            </ParameterField>
            <ParameterField info="HEX color code for v20 (#)" bannerTitle="v20 Color">
                <Input
                    className={styles.Input}
                    value={v20}
                    onChange={(e) => {
                        setV20(e.target.value);
                    }}
                />
            </ParameterField>
            <ParameterField info="HEX color code for v30 (#)" bannerTitle="v30 Color">
                <Input
                    className={styles.Input}
                    value={v30}
                    onChange={(e) => {
                        setV30(e.target.value);
                    }}
                />
            </ParameterField>
            <ParameterField info="HEX color code for v40 (#)" bannerTitle="v40 Color">
                <Input
                    className={styles.Input}
                    value={v40}
                    onChange={(e) => {
                        setV40(e.target.value);
                    }}
                />
            </ParameterField> */}
            {/* <ParameterField info="HEX color code for v50 (#)" bannerTitle="Drop shadow spinner dark theme">
                <Input
                    className={styles.Input}
                    value={v50}
                    onChange={(e) => {
                        setV50(e.target.value);
                    }}
                />
            </ParameterField> */}
            {/* <ParameterField info="HEX color code for v60 (#)" bannerTitle="Active box drop color light theme">
                <Input
                    className={styles.Input}
                    value={v60}
                    onChange={(e) => {
                        setV60(e.target.value);
                    }}
                />
            </ParameterField> */}
            <ParameterField required={true} info="HEX color code for v70 (#)" bannerTitle="Banner Color">
                <Input
                    className={styles.Input}
                    value={v70}
                    onChange={(e) => {
                        const value = e.target.value.startsWith('#') ? e.target.value : v70;
                        setV70(value);
                    }}
                />
            </ParameterField>
            <ParameterField required={true} info="HEX color code for v80 (#)" bannerTitle="Focus Color">
                <Input
                    className={styles.Input}
                    value={v80}
                    onChange={(e) => {
                        const value = e.target.value.startsWith('#') ? e.target.value : v80;
                        setV80(value);
                    }}
                />
            </ParameterField>
            {/* <ParameterField info="HEX color code for v90 (#)" bannerTitle="Active box drop color dark theme">
                <Input
                    className={styles.Input}
                    value={v90}
                    onChange={(e) => {
                        setV90(e.target.value);
                    }}
                />
            </ParameterField>
            <ParameterField info="HEX color code for v100 (#)" bannerTitle="Selected tab dark theme">
                <Input
                    className={styles.Input}
                    value={v100}
                    onChange={(e) => {
                        setV100(e.target.value);
                    }}
                />
            </ParameterField>
            <ParameterField info="HEX color code for v110 (#)" bannerTitle="Banner color dark theme">
                <Input
                    className={styles.Input}
                    value={v110}
                    onChange={(e) => {
                        setV110(e.target.value);
                    }}
                />
            </ParameterField> */}
            {/* <ParameterField info="HEX color code for v120 (#)" bannerTitle="v120 Color">
                <Input
                    className={styles.Input}
                    value={v120}
                    onChange={(e) => {
                        setV120(e.target.value);
                    }}
                />
            </ParameterField>
            <ParameterField info="HEX color code for v130 (#)" bannerTitle="v130 Color">
                <Input
                    className={styles.Input}
                    value={v130}
                    onChange={(e) => {
                        setV130(e.target.value);
                    }}
                />
            </ParameterField> */}
            {/* <ParameterField info="HEX color code for v140 (#)" bannerTitle="Drop shadow spinner">
                <Input
                    className={styles.Input}
                    value={v140}
                    onChange={(e) => {
                        setV140(e.target.value);
                    }}
                />
            </ParameterField> */}
            {/* <ParameterField info="HEX color code for v150 (#)" bannerTitle="v150 Color">
                <Input
                    className={styles.Input}
                    value={v150}
                    onChange={(e) => {
                        setV150(e.target.value);
                    }}
                />
            </ParameterField>
            <ParameterField info="HEX color code for v160 (#)" bannerTitle="v160 Color">
                <Input
                    className={styles.Input}
                    value={v160}
                    onChange={(e) => {
                        setV160(e.target.value);
                    }}
                />
            </ParameterField> */}
            <div className={styles.button}>
                <h4>
                    <u>Conversation Starter</u>
                    <InfoLabel
                        size="small"
                        info="Optional prompt starter questions to be shown on the new chat welcome screen (0-6 questions are supported"
                    ></InfoLabel>
                </h4>
                <Button onClick={handleSave} icon={<Save16Regular />} />
            </div>
            <ParameterField info="Prompt Question 1" bannerTitle="Prompt Question 1" noInfo={true}>
                <Input
                    className={styles.Input}
                    value={question1}
                    onChange={(e) => {
                        const value = e.target.value;
                        setQuestion1(value);
                    }}
                />
            </ParameterField>
            <ParameterField info="Prompt Question 2" bannerTitle="Prompt Question 2" noInfo={true}>
                <Input
                    className={styles.Input}
                    value={question2}
                    onChange={(e) => {
                        const value = e.target.value;
                        setQuestion2(value);
                    }}
                />
            </ParameterField>
            <ParameterField info="Prompt Question 3" bannerTitle="Prompt Question 3" noInfo={true}>
                <Input
                    className={styles.Input}
                    value={question3}
                    onChange={(e) => {
                        const value = e.target.value;
                        setQuestion3(value);
                    }}
                />
            </ParameterField>
            <ParameterField info="Prompt Question 4" bannerTitle="Prompt Question 4" noInfo={true}>
                <Input
                    className={styles.Input}
                    value={question4}
                    onChange={(e) => {
                        const value = e.target.value;
                        setQuestion4(value);
                    }}
                />
            </ParameterField>
            <ParameterField info="Prompt Question 5" bannerTitle="Prompt Question 5" noInfo={true}>
                <Input
                    className={styles.Input}
                    value={question5}
                    onChange={(e) => {
                        const value = e.target.value;
                        setQuestion5(value);
                    }}
                />
            </ParameterField>
            <ParameterField info="Prompt Question 6" bannerTitle="Prompt Question 6" noInfo={true}>
                <Input
                    className={styles.Input}
                    value={question6}
                    onChange={(e) => {
                        const value = e.target.value;
                        setQuestion6(value);
                    }}
                />
            </ParameterField>
            <div className={styles.button}>
                <h4>
                    <u>Custom Graphics</u>
                </h4>
                <Button onClick={handleSave} icon={<Save16Regular />} />
            </div>
            <ParameterField
                required={true}
                info="Chatbot image shown throughout the application"
                bannerTitle="Chatbot Image"
            >
                <Input
                    className={styles.Input}
                    value={chatbotImage}
                    onChange={(e) => {
                        const value = e.target.value.startsWith('https://') ? e.target.value : chatbotImage;
                        setChatbotImage(value);
                    }}
                />
            </ParameterField>
        </div>
    );
};

export default CustomizationDialog;
