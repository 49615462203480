import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { Button, Dialog, DialogBody, DialogContent, DialogSurface, DialogTitle } from '@fluentui/react-components';
import React from 'react';
import { useRole } from '../../../libs/hooks/useRole';
import { IGetToken, IPath } from '../../../libs/models/Path';
//import { FeatureKeys, Features } from '../../../redux/features/app/AppState';
import { semanticKernelDarkTheme, semanticKernelLightTheme } from '../../../styles';

interface DocumentProps {
    getToken: IGetToken | null;
    setGetToken: React.Dispatch<React.SetStateAction<IGetToken | null>>;
}

const DocumentViewer: React.FC<DocumentProps> = ({ getToken, setGetToken }) => {
    const themeSettings =
        localStorage.getItem('DarkMode') === 'true' ? { ...semanticKernelDarkTheme } : { ...semanticKernelLightTheme };
    const fileType = getToken?.filename.split('.').at(-1);
    const role = useRole();
    const [SASToken, setSASToken] = React.useState<IPath>({ uri: '' });

    React.useEffect(() => {
        if (SASToken.uri === '' && getToken !== null) {
            void role.getBlobSASToken(getToken).then((token: IPath) => {
                setSASToken(token);
            });
        }
    });

    React.useEffect(() => {
        const style = document.createElement('style');
        style.innerHTML = `
            .react-pdf__Page__textContent.textLayer {
                display: none;
            }
            .react-pdf__Page__annotations.annotationLayer{
                display: none;
            }
        `;
        document.head.appendChild(style);

        return () => {
            document.head.removeChild(style);
        };
    }, []);

    const docs = [
        {
            uri: SASToken.uri,
            fileType: fileType,
            fileName: getToken?.filename,
        },
    ];
    const theme = {
        primary: themeSettings.colorNeutralBackground1,
        secondary: themeSettings.colorNeutralBackground2,
        tertiary: themeSettings.colorNeutralBackground1,
        textPrimary: themeSettings.colorNeutralForeground1,
        textSecondary: themeSettings.colorNeutralForeground2,
        textTertiary: themeSettings.colorNeutralForeground3,
    };

    return SASToken.uri !== '' ? (
        <Dialog open={getToken !== null}>
            <DialogSurface>
                <DialogBody>
                    <DialogTitle>
                        <Button
                            onClick={() => {
                                setGetToken(null);
                                setSASToken({ uri: '' });
                            }}
                        >
                            Close
                        </Button>
                    </DialogTitle>
                    <DialogContent>
                        <DocViewer
                            theme={theme}
                            prefetchMethod="GET"
                            documents={docs}
                            pluginRenderers={DocViewerRenderers}
                        />
                    </DialogContent>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    ) : (
        <></>
    );
};

export { DocumentViewer };
