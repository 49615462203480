import { makeStyles } from '@fluentui/react-components';
import React, { useEffect, useState } from 'react';
import { useAgent } from '../libs/hooks';
import { IAgentOptions } from '../libs/models/Agent';
import { customTokens } from '../styles';
import { PortalCard } from './PortalCard';

const useStyles = makeStyles({
    cardContainer: {
        padding: '20px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start', // Align items to the left
        alignItems: 'top',
        flexWrap: 'wrap',
        backgroundColor: customTokens.colorNeutralBackground3,
    },
    cardWrapper: {
        padding: '20px',
    },
    mobileCenter: {
        '@media (max-width: 744px)': {
            justifyContent: 'center !important',
        },
    },
});

export const Portal: React.FC = () => {
    const classes = useStyles();

    const [agentSettings, setAgentSettings] = useState<IAgentOptions[]>([]);
    const agent = useAgent();

    const fetchData = async () => {
        const options: IAgentOptions[] = await agent.getOptionsList();
        // Sort agentSettings by name (case insensitive)
        const sortedOptions = options.sort((a, b) => {
            const nameA = a.name.toLowerCase();
            const nameB = b.name.toLowerCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
        setAgentSettings(sortedOptions);
    };

    useEffect(() => {
        if (agentSettings.length === 0) {
            void fetchData();
        }
    });

    return (
        <div className={classes.cardContainer}>
            {agentSettings.map((agentSetting) => (
                <div className={classes.cardWrapper} key={agentSetting.hostUrl}>
                    <PortalCard agentSettings={agentSetting} />
                </div>
            ))}
        </div>
    );
};
