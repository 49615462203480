import { useMsal } from '@azure/msal-react';
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { AuthHelper } from '../auth/AuthHelper';
import { IAgentOptions } from '../models/Agent';
import { AgentService } from '../services/AgentService';
import { BackendServiceUrl } from '../services/BaseService';
//import { usePrompt } from './usePrompt';
import { fetchCustomSettings } from '../../redux/app/thunks';
import { addAlert } from '../../redux/features/app/appSlice';
import { AlertType } from '../models/AlertType';
import { useSettings } from './useSettings';

export const useAgent = () => {
    const { instance, inProgress } = useMsal();
    const customSettings = useAppSelector((state: RootState) => state.customSettings);
    //const promptSettings = useAppSelector((state: RootState) => state.promptSettings);
    //const prompt = usePrompt();
    const settings = useSettings();
    const hostUrl = new URL(BackendServiceUrl);
    const dispatch = useAppDispatch();

    const getUrl = (hostname: string, api = false): string => {
        if (hostname === 'localhost') {
            return `http${api ? 's' : ''}://${hostname}:${api ? '40443' : '3000'}`;
        }
        return `https://${hostname}`;
    };

    const agentName =
        customSettings.customSettings.portalHostName == ''
            ? window.location.hostname
            : customSettings.customSettings.portalHostName;

    const serviceUri = getUrl(agentName, true);

    const currentAgentService = new AgentService(serviceUri);

    const getOptions = async (
        hostName?: string,
        agentService: AgentService = currentAgentService,
    ): Promise<IAgentOptions> => {
        const agentOptions = await agentService.getOptionsAsync(
            await AuthHelper.getSKaaSAccessToken(instance, inProgress),
            hostName ?? hostUrl.hostname.split('.')[0],
        );
        return agentOptions;
    };

    const getOptionsList = async (agentService: AgentService = currentAgentService): Promise<IAgentOptions[]> => {
        try {
            const agentOptions = await agentService.getOptionsListAsync(
                await AuthHelper.getSKaaSAccessToken(instance, inProgress),
            );
            return agentOptions;
        } catch {
            const agentOptions = new Array<IAgentOptions>();
            return agentOptions;
        }
    };

    const setOptions = async (
        options: IAgentOptions,
        hostName?: string,
        agentService: AgentService = currentAgentService,
    ): Promise<void> => {
        await agentService.setOptionsAsync(
            await AuthHelper.getSKaaSAccessToken(instance, inProgress),
            hostName ?? hostUrl.hostname,
            options,
        );
    };

    const setOption = async (
        key: keyof IAgentOptions,
        value: any,
        agentService: AgentService = currentAgentService,
    ): Promise<void> => {
        const currentOptions = await getOptions(undefined, agentService);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        await setOptions({ ...currentOptions, [key]: value }, currentOptions.hostName, agentService);
    };

    const deleteOptions = async (hostName: string, agentService: AgentService = currentAgentService): Promise<void> => {
        await agentService.deleteOptionsAsync(await AuthHelper.getSKaaSAccessToken(instance, inProgress), hostName);
    };

    // These settings are available from prompt settings.
    const getPortalSettings = (): IPortalSettings => {
        const settings: IPortalSettings = {
            allowPortal: customSettings.customSettings.allowPortal,
            portalHostName: customSettings.customSettings.portalHostName,
            portalName: customSettings.customSettings.portalName,
            portalBannerColor: customSettings.customSettings.portalBannerColor,
            portalBannerLink: customSettings.customSettings.portalBannerLink,
        };
        return settings;
    };

    const validateHostname = async (hostname: string): Promise<boolean> => {
        const url = getUrl(hostname, true);
        try {
            const response = await fetch(url);
            if (response.ok) {
                return true;
            } else {
                return false;
            }
        } catch (error) {
            console.error('Error fetching url. Provide a working endpoint');
        }
        return false;
    };

    interface IPortalSettings {
        allowPortal: boolean;
        portalHostName: string;
        portalName: string;
        portalBannerColor: string;
        portalBannerLink?: string;
    }

    const savePortalSetting = async (
        allowPortal: boolean,
        portalHostName: string,
        portalName: string,
        portalBannerColor: string,
        portalBannerLink?: string,
    ) => {
        // Replace this with the actual implementation await
        const currentPortalSettings: IPortalSettings = getPortalSettings();
        const currentAgentOptions: IAgentOptions = {
            name: customSettings.customSettings.appTitle,
            hostName: window.location.hostname,
            welcomeImageUrl: customSettings.customSettings.welcomeImageUrl,
            welcomeSubtitle: customSettings.customSettings.welcomeSubtitle,
            hostUrl: window.location.origin,
            color: customSettings.customSettings.brandVariants.v70,
            bannerLink: customSettings.customSettings.portalBannerLink ?? '',
        };
        // eslint-disable-next-line @typescript-eslint/consistent-indexed-object-style
        const updateParams: { [key: string]: any } = {};
        // eslint-disable-next-line @typescript-eslint/consistent-indexed-object-style
        let deleteAgent = false;

        const isValid = await validateHostname(portalHostName);
        if (!isValid) {
            const errorMessage = 'Please provide a valid endpoint for the Portal Master';
            const alert = AlertType.Error;
            dispatch(addAlert({ message: errorMessage, type: alert }));
            return;
        }
        // A new host is chosen for the Portal service
        if (currentPortalSettings.portalHostName && currentPortalSettings.portalHostName !== portalHostName) {
            updateParams.portalHostName = portalHostName;
            deleteAgent = true;
        }
        if (currentPortalSettings.allowPortal !== allowPortal && !allowPortal) {
            deleteAgent = true;
        }
        if (currentAgentOptions.bannerLink !== portalBannerLink) {
            currentAgentOptions.bannerLink = portalBannerLink ?? '';
            updateParams.portalBannerLink = portalBannerLink ?? '';
        }

        if (deleteAgent) {
            try {
                await deleteOptions(currentAgentOptions.hostName);
            } catch {}
        }
        // Update the Agent registration
        if (allowPortal) {
            const newAgentService = new AgentService(getUrl(portalHostName, true));
            await setOptions(currentAgentOptions, currentAgentOptions.hostName, newAgentService);
            updateParams.portalHostName = portalHostName;
        }

        // Update protal settings here
        if (currentAgentOptions.hostName === portalHostName) {
            updateParams.portalName = portalName;
            updateParams.portalBannerColor = portalBannerColor;
        }
        if (customSettings.customSettings.allowPortal !== allowPortal) {
            updateParams.allowPortal = allowPortal;
        }

        if (Object.keys(updateParams).length > 0) {
            await settings.setSettings({ ...customSettings.customSettings, ...currentPortalSettings, ...updateParams });
            dispatch(fetchCustomSettings() as any);
        }
    };

    return {
        getOptions,
        getOptionsList,
        setOptions,
        setOption,
        validateHostname,
        savePortalSetting,
        getUrl,
    };
};
